import { createStore } from 'redux'
import {getNested, setNested} from "./p9libs/utils/misc";

export const ACTION_TYPE_CHANGE_PORTAL_SERVICE = 'portal_service/select';
export const ACTION_TYPE_UPDATE_SELECTED_ENTITY = 'selected_entity/update_entity';
export const ACTION_TYPE_UPDATE_SELECTED_ENTITY_CODE = 'selected_entity/update_entity_code';

export const ACTION_TYPE_REFRESH_ISSUES = 'issues/refresh';
export const ACTION_TYPE_UPDATE_PORTAL_FEATURES = 'portal/update_features';

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  'issues_request_id': 1,
  portalService: '',
  selectedEntity: {},
  selectedEntitySeqId: 1,
  portalFeatures: {},
}

const changeState = (state = initialState, { type, ...rest }) => {
  let newState = {};

  switch (type) {
    case 'set':
      return {...state, ...rest }
    case ACTION_TYPE_UPDATE_PORTAL_FEATURES:
      newState = Object.assign({}, state);
      return {
        ...newState,
        ...rest,
      };
    case ACTION_TYPE_REFRESH_ISSUES:
      newState = Object.assign({}, state);

      return {
        ...newState,
        ...rest,
        'issues_request_id': state['issues_request_id'] + 1,
      };
    case ACTION_TYPE_CHANGE_PORTAL_SERVICE:
      newState = Object.assign({}, state);
      return {
        ...newState,
        ...rest,
      };
    case ACTION_TYPE_UPDATE_SELECTED_ENTITY:
      return (() => {
        const newState = Object.assign({}, state);
        const mode = getNested(rest, ['mode'], '');
        const entity = getNested(rest, ['entity'], {});
        const entityCode = getNested(rest, ['entityCode'], null);
        newState.selectedEntitySeqId += 1;
        setNested(newState, ['selectedEntity', mode, 'entity'], entity);
        setNested(newState, ['selectedEntity', mode, 'entityCode'], entityCode);

        return {
          ...newState,
          ...rest,
        };
      })();
    case ACTION_TYPE_UPDATE_SELECTED_ENTITY_CODE:
      return (() => {
        const newState = Object.assign({}, state);
        const mode = getNested(rest, ['mode'], '');
        const entityCode = getNested(rest, ['entityCode'], {});
        newState.selectedEntitySeqId += 1;
        setNested(newState, ['selectedEntity', mode, 'entityCode'], entityCode);

        return {
          ...newState,
        };
      })();
    default:
      return state
  }
}

const store = createStore(changeState);
export default store;
