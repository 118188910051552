import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Auth } from 'src/components/base/auth';


/**
 * Props:
 * render: function(props).
 */
export class LoginRequiredRoute extends React.Component {
  render() {
    if (!Auth.isAuthenticated()) {
      return (
        <Redirect to="/login" />
      );
    }

    return (
      <Route path={this.props.path} render={this.props.render} />
    );
  }
}
