import { redirect } from "src/uimisc";
import { getNested } from "../../p9libs/utils/misc";
import { Auth } from "./auth";


export const ERROR_NOT_LOGGED_IN = 13;
export const ERROR_NO_PERMISSION = 14;



export class SrvComm {

  static AJAX_URL = '/ui_api';
  static FORM_DATA_URL = '/form_api';

  static sendFormData(command, formData, onSuccess, onFail) {
    const fetchUrl = `${this.FORM_DATA_URL}/${command}`;
    fetch(fetchUrl, {
      'method': 'POST',
      'body': formData,
    }).then((response) => response.json()).then((respData) => {
      const result = getNested(respData, ['result'], '');
      if (result === 'OK') {
        const data = getNested(respData, ['data'], {});
        onSuccess(data);
      } else {
        const error = getNested(respData, ['error'], {});
        onFail(error);
      }
    }).catch((error) => {
      onFail();
    });
  }

  /**
   * Sends a command to the server.
   * @param {string} command
   * @param {object} params
   * @param {function(respData, requestId)} onSuccess
   * @param {function(respData, requestId)} onFail
   * @param {boolean} refreshPageOnLoginError
   */
  static sendCommand(command, params, onSuccess, onFail, refreshPageOnLoginError) {
    if (refreshPageOnLoginError === undefined) refreshPageOnLoginError = true;

    // const requestId = `${group}_${command}_${Date.now()}`;
    const requestId = Date.now();
    const req = {
      'command': command,
      'request_id': requestId,
      'params': params,
    };
    const httpRequest = this.ajaxRequestDefault(req, respData => {
      const respResult = getNested(respData, ['result'], '');
      if (respResult === 'OK') {
        if (onSuccess !== undefined) {
          const data = getNested(respData, ['data'], {});
          onSuccess(data, requestId);
        }
      } else if (respResult === 'ERROR') {
        if (onFail !== undefined) {
          onFail(respData, requestId);
        }
      }
    }, respData => {
      console.error('sendCommand() failed.');
      if (onFail !== undefined) {
        onFail(respData);
      }
    }, undefined, refreshPageOnLoginError);
    return [requestId, httpRequest];
  }


  static ajaxRequestDefault(reqData, onSuccess, onFail, onComplete, refreshPageOnLoginError) {
    // const jwt = Auth.getJwt();
    const req = {
      'request': reqData,
      // 'jwt': jwt,
    };
    // if (jwt !== null) {
    //   req['jwt'] = jwt;
    // }
    // P9W
    return this.ajaxRequest(this.AJAX_URL, req, onSuccess, onFail, onComplete, refreshPageOnLoginError);
  }


  /**
   *
   * @param {*} reqUrl string. Request URL.
   * @param {*} reqData dict. Request data.
   * @param {*} onSuccess function(respData).
   * @param {*} onFail function()
   * @param {*} onComplete function().
   * @param {*} refreshPageOnLoginError boolean.
   */
  static ajaxRequest(reqUrl, reqData, onSuccess, onFail, onComplete, refreshPageOnLoginError) {
    console.debug(`Loading start (${(new Date()).toLocaleTimeString()}).`);

    const httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = () => {
      // XMLHttpRequest.UNSENT = 0
      // XMLHttpRequest.OPENED = 1
      // XMLHttpRequest.HEADERS_RECEIVED = 2
      // XMLHttpRequest.LOADING = 3
      // XMLHttpRequest.DONE = 4

      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        console.debug(`Loaded (${(new Date()).toLocaleTimeString()}).`);

        try {
          if (httpRequest.status === 200) {
            if (onSuccess) {
              const respData = JSON.parse(httpRequest.responseText);

              // Check for JWT error
              const result = getNested(respData, ['result'], '');
              if (result === 'ERROR') {
                const errCode = getNested(respData, ['error', 'code'], 0);

                if (onFail) {
                  onFail(respData);
                }

                if (errCode === ERROR_NOT_LOGGED_IN) {
                  console.error('Not logged in.');
                  console.log(reqUrl);
                  Auth.logout();
                  if (refreshPageOnLoginError) {
                    if (!window.location.pathname.startsWith('/password_recovery/')) {
                      redirect('/login');
                    }
                  }
                  return;
                }
              }

              onSuccess(respData);
            }
          } else {
            console.error('There was a problem with the AJAX request.');
            if (onFail) {
              onFail();
            }
          }

          if (onComplete) {
            onComplete();
          }
        } catch (e) {
          console.error('AJAX request failed.');
          console.error(e);
          if (onFail) {
            onFail();
          }
        }
      } else {
        // console.log(`[${httpRequest.responseText}]`);
        console.debug(`Loading (${(new Date()).toLocaleTimeString()}) ...`);
      }
    };
    httpRequest.open('POST', reqUrl);
    httpRequest.send(JSON.stringify(reqData));
    return httpRequest;
  }
}
