import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoLoginPage } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilChartPie,
  cilTransfer,
  cilDataTransferUp,
  cilDataTransferDown,
  cilChartLine,
  cilFile,
  cilCloud,
  cilBrowser,
  cilUser,
  cilLockLocked,
  cilUserPlus,
  cilBank,
  cilInstitution,
  cilSpreadsheet,
  cilLoopCircular,
  cilLayers,
  cilDollar,
  cilEuro,
  cilCash,
  cilListNumbered,
  cilBellExclamation,
  cilWarning,
  cilList,
  cilCode,
  cilGroup,
  cilScrubber,
  cilLibrary,
  cilLifeRing,
  cilListFilter,
  cilInfo,
  cilSettings,
  cilCalculator,
  cilBell,
} from '@coreui/icons'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative,
  logoLoginPage,
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilChartPie,
  cilTransfer,
  cilDataTransferUp,
  cilDataTransferDown,
  cilChartLine,
  cilFile,
  cilCloud,
  cilBrowser,
  cilUser,
  cilLockLocked,
  cilUserPlus,
  cilBank,
  cilInstitution,
  cilSpreadsheet,
  cilLoopCircular,
  cilLayers,
  cilDollar,
  cilEuro,
  cilCash,
  cilListNumbered,
  cilBellExclamation,
  cilWarning,
  cilList,
  cilCode,
  cilGroup,
  cilScrubber,
  cilLibrary,
  cilLifeRing,
  cilListFilter,
  cilInfo,
  cilSettings,
  cilCalculator,
  cilBell,
})
