import {getNested, setNested} from 'src/p9libs/utils/misc';
import {SrvComm} from 'src/components/base/srvcomm';


export const ERROR_LOGIN_FAILED = 10001;


/**
 * Handles authentication.
 */
export class Auth {
  /**
   * @param {*} userInfo
   *      schemas/auth.json#/definitions/user_info
   * @returns
   */
  static setUserInfo(userInfo) {
    if (userInfo === null) {
      localStorage.removeItem('user_info');
      return;
    }
    localStorage.setItem('user_info', JSON.stringify(userInfo));
  }

  static getUserInfo(userInfo) {
    return JSON.parse(localStorage.getItem('user_info'));
  }

  static getUserInfoPath(path, defaultValue = null) {
    const userInfo = Auth.getUserInfo();
    return getNested(userInfo, path, defaultValue);
  }

  static setUserInfoPath(path, value) {
    const userInfo = Auth.getUserInfo();
    setNested(userInfo, path, value);
    Auth.setUserInfo(userInfo);
  }

  static getUsername() {
    // return localStorage.getItem('user_info_username');
    return getNested(this.getUserInfo(), ['username'], '');
  }

  static getRoles() {
    return getNested(this.getUserInfo(), ['roles'], []);
    // const roles = JSON.parse(localStorage.getItem('user_info_roles'));
    // if (!roles) return [];
    // return roles;
  }

  // static setJwt(jwt) {
  //   // localStorage.setItem('auth_jwt', jwt);
  //   if (jwt == null) {
  //     // Removing the cookie.
  //     document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  //     return;
  //   }
  //   document.cookie = `jwt=${jwt}; SameSite=Lax`;
  // }

  // static getJwt() {
  //   const res = document.cookie.replace(/(?:(?:^|.*;\s*)jwt\s*\=\s*([^;]*).*$)|^.*$/, "$1");
  //   return !res ? null : res;
  //   // const jwt = localStorage.getItem('auth_jwt');
  //   // if (jwt == 'null') return null;
  //   // return jwt;
  // }

  /**
   * @param {schema/auth.json#/definitions/login_request} loginData
   * @param {function(data)} onSuccess
   * @param {function(<array of {schema/main.json#/definitions/error}>)} onFail
   * @param {function({schema/auth.json#/definitions/login_result})} onResponse
   */
  static login(loginData, onSuccess, onFail, onResponse) {
    SrvComm.sendCommand('auth/login', loginData, respData => {
      // {schema/auth.json#/definitions/login_result}
      const result = getNested(respData, ['result'], '');
      if (result === 'OK') {
        const data = getNested(respData, ['data'], {});
        const nextAction = getNested(data, ['next_action'], '');
        if (!nextAction) {
          this.setUserInfo(getNested(data, ['user_info'], {}));
          // this.setUsername(getNested(data, ['user_info', 'username'], ''));
          // this.setRoles(getNested(data, ['user_info', 'roles'], []));

          const jwt = getNested(data, ['jwt'], null);
          // this.setJwt(jwt);
          // this._updateRoutes();
          // document.cookie = `jwt=${jwt}`;

          // this.getEntities()
        }

        if (onSuccess) onSuccess(data);
        if (onResponse) onResponse(respData);
      } else {
        const errors = getNested(respData, ['errors'], []);
        if (onFail) onFail(errors);
        if (onResponse) onResponse(respData);
      }
    }, () => {
      const errs = [
        {
          'code': ERROR_LOGIN_FAILED,
          'message': 'Login failed.',
        }
      ];
      const res = {
        'result': 'errors',
        'errors': errs,
      };
      if (onFail) onFail(errs);
      if (onResponse) onResponse(res);
    });
  }

  static makeLogoutRequest(onSuccess, onFail, onFinish) {
    SrvComm.sendCommand('auth/logout', {}, (respData, requestId) => {
      if (onSuccess) onSuccess(respData, requestId);
      if (onFinish) onFinish(respData, requestId);
    }, (respData, requestId) => {
      if (onFail) onFail(respData, requestId);
      if (onFinish) onFinish(respData, requestId);
    });
  }

  static logout() {
    this.setUserInfo(null);
  }

  static isAuthenticated() {
    const userInfo = this.getUserInfo();
    if (userInfo == null) return false;
    if (userInfo && Object.keys(userInfo).length === 0 && Object.getPrototypeOf(userInfo) === Object.prototype) {
      return false;
    }
    return true;
    return this.getUserInfo() != null;
  }
}
