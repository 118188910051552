// export const sygnet = ['160 160', `
//   <title>coreui logo</title>
//   <g>
//     <g style="fill: currentColor;">
//       <path d="M125,47.091,86,24.5743a12,12,0,0,0-12,0L35,47.091a12.0336,12.0336,0,0,0-6,10.3923v45.0334a12.0335,12.0335,0,0,0,6,10.3923l39,22.5166a11.9993,11.9993,0,0,0,12,0l39-22.5166a12.0335,12.0335,0,0,0,6-10.3923V57.4833A12.0336,12.0336,0,0,0,125,47.091Zm-2,55.4257a4,4,0,0,1-2,3.464L82,128.4974a4,4,0,0,1-4,0L39,105.9807a4,4,0,0,1-2-3.464V57.4833a4,4,0,0,1,2-3.4641L78,31.5025a4,4,0,0,1,4,0l39,22.5167a4,4,0,0,1,2,3.4641Z"/>
//       <path d="M103.0216,93.0379h-2.866a4,4,0,0,0-1.9246.4935L80.95,103.0167,61,91.4981V68.5206L80.95,57.002l17.2894,9.455a4,4,0,0,0,1.9192.4905h2.8632a2,2,0,0,0,2-2V62.2357a2,2,0,0,0-1.04-1.7547L84.793,49.9854a8.0391,8.0391,0,0,0-7.8428.09L57,61.5929A8.0243,8.0243,0,0,0,53,68.5216v22.976a8,8,0,0,0,4,6.9283l19.95,11.5185a8.0422,8.0422,0,0,0,7.8433.0879l19.19-10.5311a2,2,0,0,0,1.0378-1.7534v-2.71A2,2,0,0,0,103.0216,93.0379Z"/>
//     </g>
//   </g>
// `]

export const sygnet = ['160 160', `
  <title>Point Nine Icon Logo</title>
  <?xml version="1.0" encoding="UTF-8"?>
  <svg xmlns="http://www.w3.org/2000/svg" id="svg970" viewBox="0 0 2868.34 2755.57">
	 <defs>
		<style>.cls-1{fill:#470a68;}.cls-2{fill:#fff;}</style>
	 </defs>
	 <g id="g3174">
		<path id="path1430" class="cls-1" d="M1003.86,2788.08,51.72,1138.8,1967.83,32.51l952.23,1649.24Z" transform="translate(-51.72 -32.51)" />
		<g id="g1431">
		   <path id="path1432" class="cls-2" d="M1918,1136.63l-47.27-81.89,95.16-55,47.32,81.89Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1433" class="cls-2" d="M2036.84,1453.86V1154.37h99.46v44.24q46-50.88,101.15-50.88c36.13,0,64.45,11.15,85.31,33.36,20.69,21.9,31.1,51.87,31.1,90.17v182.56H2244.51V1304.05l-.47-24c0-11.32-4.25-20.77-12.58-28.5a40.35,40.35,0,0,0-29.58-12c-19.56,0-34.92,8.41-45.89,25.15-6.63,9.76-9.8,24.77-9.8,44.81v144.26H2036.84Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1434" class="cls-2" d="M1530.06,1780.42V1480.88l109.39-.56v44.33c30.58-33.88,54.39-50.27,91.22-50.27q54.13,0,85.27,33.31,31.1,32.85,31.14,90.17v182.56H1737.74V1630.65l-.56-24a37.05,37.05,0,0,0-12.59-28.41c-8-8.06-17.82-12-29.49-12-19.69,0-35.09,8.41-46,25.11q-9.76,14.84-9.76,44.81v144.3Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1435" class="cls-2" d="M2036.71,1780.42V1480.88h99.42v44.33q45.91-50.88,101.1-50.83,54.2,0,85.31,33.31,31,32.85,31.1,90.17v182.56H2244.3V1630.65l-.48-24a36.91,36.91,0,0,0-12.58-28.41c-8-8.06-17.83-12-29.49-12-19.65,0-35.09,8.41-46,25.11q-9.83,14.84-9.8,44.81v144.3Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1436" class="cls-2" d="M1918,1933.1l-47.32-81.89,95.12-54.87,47.28,81.89Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1437" class="cls-2" d="M2388.25,1154.37v-72.18H2497.6v72.18h68.32v82.49h-67.84v114.29a29.07,29.07,0,0,0,6.59,18.61c6.94,8,18.52,12,34.92,12,9.11,0,19.39-1.87,30.66-5.51l11.5,69.39c-29.15,9.46-55.09,14.23-77.64,14.23-35.44,0-63.55-8.37-84.76-25.11a81.73,81.73,0,0,1-24.5-34.48c-4.47-13.1-6.6-32.45-6.6-57.91v-188Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1438" class="cls-2" d="M1887.08,1780.46V1154.63h109.81v625.83Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1439" class="cls-2" d="M1363.08,1145.52c-36.92,0-66.23,11.32-88.88,33.92-3.94,4.38-6.59,7.85-8.06,9.63-1.05,1.47-3.13,4.59-6.38,9.71v-45.19H1158v410.05h111.42V1426.45c28.46,22.9,57.65,33.88,87.18,33.88,39.73,0,71.74-14.93,96.9-45.2,25.46-30.32,38.69-67.62,38.69-114.64,0-47.36-12.49-85.66-35.52-113C1433.77,1159.75,1402.37,1145.52,1363.08,1145.52Zm-37.18,85.58c17.09,0,30.06,5.89,40.34,19.38,10.59,13.14,16.14,30.15,16.14,51.66s-4.34,39.82-14.53,53.31c-9.85,13.09-24,19.39-40.43,19.39-17.09,0-30.62-7-41.9-19.39-11.36-12.8-16.22-28.76-16.22-48.41v-4.9c0-21.08,4-38.52,14.57-51.66C1294.85,1237,1309.08,1231.1,1325.9,1231.1Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1443" class="cls-2" d="M1684.26,1148.73c-51.83,0-94.56,13.18-126,41.94-31,28.41-45.2,67.23-45.2,114.64,0,46.23,15.57,82.93,46.81,111.34,31.66,28.41,73.3,43.59,124.35,43.59,52,0,92.86-15.14,124.31-43.59,31.35-28.76,46.75-66,46.75-113,0-46.68-15-84.54-46.75-113C1776.86,1162.26,1735.66,1148.73,1684.26,1148.73Zm0,77.51c17.09,0,29.71,6.81,40.33,21,10.59,13.79,16.14,33.14,16.14,56.47,0,22.9-5.21,41.94-16.14,56.47-10.53,14.14-23.59,20.95-40.33,20.95s-31.41-6.81-41.94-20.95-16.14-33.22-16.14-56.47c0-22.6,5.9-41.9,16.14-56.47C1653.2,1233,1667.52,1226.24,1684.26,1226.24Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1444" class="cls-2" d="M2542.92,1474.86c-46.58,0-85.79,13.18-114.58,41.94s-42,67.1-42,113c0,47.71,16.35,86.18,48.45,114.59,32.4,28.41,73.78,42,125.87,42,33.92,0,64.8-5.6,93.64-16.14l24.16-9.67-27.41-66.19c-32.79,12-61,17.74-84,17.79-19.6,0-36.22-3.26-50-11.33-13.53-8.41-21.16-19.17-22.64-32.31h200.13l1.65-16.18c0-56.82-13.8-101.4-40.34-132.33S2592.2,1474.86,2542.92,1474.86Zm0,72.56c29.63,0,48.37,16.92,55,50.09H2491.31c2.86-15.7,9.06-26.8,17.78-35.47C2518.29,1553.19,2529.18,1547.42,2542.92,1547.42Z" transform="translate(-51.72 -32.51)" />
		</g>
	 </g>
  </svg>
`]

