export function getNested(obj, path, defaultValue) {
  try {
    for (let i = 0; i < path.length; i++) {
      const key = path[i];
      obj = obj[key];
      if (obj === undefined) return defaultValue;
    }
    if (obj === undefined) return defaultValue;
    return obj;
  } catch (error) {
    return defaultValue;
  }
}


export function setNested(obj, path, value, overWriteSubtrees) {
  if (overWriteSubtrees === undefined) overWriteSubtrees = true;

  for (let i = 0; i < path.length; i++) {
    const key = path[i];

    if (i == path.length - 1) {
      obj[key] = value;
      return true;
    }

    if (obj[key] === undefined) {
      obj[key] = {};
      obj = obj[key];
      continue;
    }

    if ((typeof(obj[key]) == 'object') && (obj[key] !== null)) {
      obj = obj[key];
      continue;
    }

    if (overWriteSubtrees) {
      obj[key] = {};
      obj = obj[key];
      continue;
    }

    return false;
  }
}


export function capitalize(s) {
  if (typeof(s) !== 'string') return s;
  if (s.length === 0) return '';
  return s[0].toUpperCase() + s.slice(1);
}


export function makeRange(start, end) {
  const arrayLength = end - start + 1;
  if (arrayLength <= 0) {
    return [];
  }
  return (new Array(arrayLength)).fill(undefined).map((_, i) => i + start);
}


export function objectsEqual(obj1, obj2, excludePaths) {
  if (!excludePaths) excludePaths = [];
  const obj1Paths = getObjPaths(obj1)[0].filter(path => {
    return !excludePaths.some(excludePath => {
      return isSubpath(path, excludePath);
    });
  });
  const obj2Paths = getObjPaths(obj2)[0].filter(path => {
    return !excludePaths.some(excludePath => {
      return isSubpath(path, excludePath);
    });
  });

  const pathsEqual = (a, b) => {
     const aStr = a.map(x => x.join('|')).sort();
     const bStr = b.map(x => x.join('|')).sort();
     return arraysEqual(aStr, bStr);
  };

  if (!pathsEqual(obj1Paths, obj2Paths)) {
    return false;
  }

  return obj1Paths.every(path => {
    return getNested(obj1, path, null) === getNested(obj2, path, null);
  });
}


export function isSubpath(subpath, path) {
  for (let i = 0; i < path.length; i++) {
    if (subpath[i] !== path[i]) return false;
  }
  return true;
}


export function arraysEqual(a, b) {
  return Array.isArray(a) && Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index]);
}


export function getObjPaths(d, leavesOnly=true) {
  const paths = [];
  const objects = [];

  function dictPaths(d, leavesOnly=true, path=[]) {
    const obj = getNested(d, path, null);

    if (leavesOnly) {
      if ((typeof(obj) !== 'object') || (obj === null)) {
        paths.push(path);
        objects.push(obj);
        return;
      }
    } else {
      if (path.length > 0) {
        paths.push(path);
        objects.push(obj);
      }
      if (typeof(obj) !== 'object') return;
    }

    Object.keys(obj).forEach(key => {
      const newPath = path.concat([key]);
      dictPaths(d, leavesOnly, newPath);
    });
  }

  dictPaths(d, leavesOnly, []);

  return [paths, objects];
}

export function uniqueArray(arrArg) {
  return arrArg.filter(function(elem, pos,arr) {
    return arr.indexOf(elem) == pos;
  });
}
