// export const logo = ['690 134', `
//   <title>coreui react pro</title>
//   <g>
//     <g style="fill:#00a1ff">
//       <path d="M362.0177,90.1512,353.25,69.4149a.2507.2507,0,0,0-.2559-.1914H343.01a.2263.2263,0,0,0-.2559.2559V90.0233a.5657.5657,0,0,1-.64.64h-1.2163a.5652.5652,0,0,1-.64-.64V46.5028a.5655.5655,0,0,1,.64-.64H353.442a9.9792,9.9792,0,0,1,7.7437,3.2324A12.2,12.2,0,0,1,364.13,57.64a12.4389,12.4389,0,0,1-2.24,7.584,9.37,9.37,0,0,1-6.08,3.7441c-.1709.086-.2139.1915-.128.3194l8.7041,20.6084.064.2558q0,.5127-.5757.5118h-1.1523A.703.703,0,0,1,362.0177,90.1512ZM342.754,48.3593v18.496a.2259.2259,0,0,0,.2559.2559h10.3037a7.6713,7.6713,0,0,0,6.0166-2.5918,9.8807,9.8807,0,0,0,2.3037-6.8164,10.2875,10.2875,0,0,0-2.272-6.9756,7.6033,7.6033,0,0,0-6.0483-2.624H343.01A.2263.2263,0,0,0,342.754,48.3593Z"/>
//       <path d="M401.3263,48.1034H381.2945a.2262.2262,0,0,0-.2558.2559v18.496a.2259.2259,0,0,0,.2558.2559h13.8238a.5664.5664,0,0,1,.6406.64v.96a.5663.5663,0,0,1-.6406.6406H381.2945a.2263.2263,0,0,0-.2558.2559v18.56a.2258.2258,0,0,0,.2558.2558h20.0318a.5671.5671,0,0,1,.6406.6407v.96a.566.566,0,0,1-.6406.64H379.1827a.5653.5653,0,0,1-.64-.64V46.5028a.5656.5656,0,0,1,.64-.64h22.1436a.5664.5664,0,0,1,.6406.64v.96A.5663.5663,0,0,1,401.3263,48.1034Z"/>
//       <path d="M439.047,90.1512l-2.4317-8.832a.2971.2971,0,0,0-.32-.1924H419.5274a.2957.2957,0,0,0-.32.1924l-2.3681,8.7676a.6577.6577,0,0,1-.7036.5762H414.919a.5385.5385,0,0,1-.5756-.7041l12.0317-43.584a.6436.6436,0,0,1,.7041-.5117h1.6a.6442.6442,0,0,1,.7041.5117l12.16,43.584.0644.1923q0,.5127-.64.5118h-1.2163A.6428.6428,0,0,1,439.047,90.1512ZM419.9435,78.9188a.3031.3031,0,0,0,.2236.0967h15.4883a.3048.3048,0,0,0,.2236-.0967c.0645-.0635.0742-.1162.0322-.1592l-7.872-28.9287c-.043-.0849-.086-.1279-.128-.1279s-.0859.043-.1279.1279L419.9112,78.76C419.8683,78.8026,419.879,78.8553,419.9435,78.9188Z"/>
//       <path d="M456.6017,87.911a11.6372,11.6372,0,0,1-3.3277-8.7041V57.1913a11.4158,11.4158,0,0,1,3.36-8.5762,12.0941,12.0941,0,0,1,8.8-3.2637,12.2566,12.2566,0,0,1,8.8643,3.2315,11.3927,11.3927,0,0,1,3.36,8.6084v.64a.5663.5663,0,0,1-.6406.6407l-1.28.0634q-.6408,0-.64-.5761v-.8321a9.289,9.289,0,0,0-2.6558-6.9121,10.6734,10.6734,0,0,0-14.0161,0,9.2854,9.2854,0,0,0-2.6563,6.9121V79.3993a9.2808,9.2808,0,0,0,2.6563,6.9121,10.67,10.67,0,0,0,14.0161,0,9.2843,9.2843,0,0,0,2.6558-6.9121v-.7686q0-.5757.64-.5752l1.28.0635a.5667.5667,0,0,1,.6406.6406v.5118a11.4952,11.4952,0,0,1-3.36,8.64,13.6227,13.6227,0,0,1-17.6963,0Z"/>
//       <path d="M514.4376,46.5028v.96a.5658.5658,0,0,1-.64.6406H503.046a.2263.2263,0,0,0-.2559.2559v41.664a.566.566,0,0,1-.6406.64h-1.2158a.5652.5652,0,0,1-.64-.64V48.3593a.2266.2266,0,0,0-.2558-.2559H489.8619a.5656.5656,0,0,1-.64-.6406v-.96a.5656.5656,0,0,1,.64-.64H513.798A.5658.5658,0,0,1,514.4376,46.5028Z"/>
//       <path d="M522.0665,89.5116a2.8385,2.8385,0,0,1-.8-2.0488,2.9194,2.9194,0,0,1,.8-2.1114,2.7544,2.7544,0,0,1,2.08-.832,2.8465,2.8465,0,0,1,2.9438,2.9434,2.7541,2.7541,0,0,1-.832,2.08,2.9221,2.9221,0,0,1-2.1118.8008A2.754,2.754,0,0,1,522.0665,89.5116Z"/>
//       <path d="M542.4054,88.0077a11.3123,11.3123,0,0,1-3.2-8.416v-5.44a.5656.5656,0,0,1,.64-.64h1.2158a.5661.5661,0,0,1,.64.64v5.5039a9.1424,9.1424,0,0,0,2.5283,6.72,8.9745,8.9745,0,0,0,6.6875,2.5605,8.7908,8.7908,0,0,0,9.28-9.28V46.5028a.5655.5655,0,0,1,.64-.64h1.2163a.566.566,0,0,1,.64.64V79.5917a11.2545,11.2545,0,0,1-3.2325,8.416,13.0618,13.0618,0,0,1-17.0556,0Z"/>
//       <path d="M580.35,88.1034a10.4859,10.4859,0,0,1-3.36-8.1279v-1.792a.5663.5663,0,0,1,.64-.6407h1.0884a.5668.5668,0,0,1,.64.6407v1.6a8.5459,8.5459,0,0,0,2.752,6.6562,10.5353,10.5353,0,0,0,7.36,2.4961,9.8719,9.8719,0,0,0,6.9761-2.3681,8.2161,8.2161,0,0,0,2.56-6.336,8.4,8.4,0,0,0-1.12-4.416,11.3812,11.3812,0,0,0-3.3281-3.3926,71.6714,71.6714,0,0,0-6.1763-3.7119,71.0479,71.0479,0,0,1-6.24-3.84,12.1711,12.1711,0,0,1-3.4238-3.68,10.2614,10.2614,0,0,1-1.28-5.3438,9.8579,9.8579,0,0,1,3.0718-7.7441,12.0122,12.0122,0,0,1,8.32-2.752q5.6954,0,8.96,3.1036a10.8251,10.8251,0,0,1,3.2642,8.2246v1.6a.5658.5658,0,0,1-.64.64h-1.1519a.5652.5652,0,0,1-.64-.64V56.8075a8.8647,8.8647,0,0,0-2.624-6.6885,9.9933,9.9933,0,0,0-7.232-2.5273,9.37,9.37,0,0,0-6.5278,2.1435,7.8224,7.8224,0,0,0-2.3682,6.1123,7.8006,7.8006,0,0,0,1.0244,4.16,10.387,10.387,0,0,0,3.0078,3.0391,62.8714,62.8714,0,0,0,5.9522,3.4882,71.0575,71.0575,0,0,1,6.72,4.2559,13.4674,13.4674,0,0,1,3.648,3.9365,10.049,10.049,0,0,1,1.28,5.1836,10.7177,10.7177,0,0,1-3.2637,8.1924q-3.2637,3.0717-8.832,3.0723Q583.71,91.1757,580.35,88.1034Z"/>
//     </g>
//     <g style="fill:#80d0ff;">
//       <path d="M672.3,59.6528a3.4937,3.4937,0,0,0-3.1992,0,2.6556,2.6556,0,0,0-1.0645,1.1016,3.4924,3.4924,0,0,0-.375,1.6484V67.645a3.4925,3.4925,0,0,0,.375,1.6485,2.6559,2.6559,0,0,0,1.0645,1.1015,3.4937,3.4937,0,0,0,3.1992,0,2.6551,2.6551,0,0,0,1.0644-1.1015,3.4926,3.4926,0,0,0,.3755-1.6485V62.4028a3.4925,3.4925,0,0,0-.3755-1.6484A2.6547,2.6547,0,0,0,672.3,59.6528Z"/>
//       <path d="M685.49,46h-66a2,2,0,0,0-2,2V81.9161a2,2,0,0,0,2,2h66a2,2,0,0,0,2-2V48A2,2,0,0,0,685.49,46ZM639.5851,64.439a4.8213,4.8213,0,0,1-1.9751,1.914,6.1869,6.1869,0,0,1-2.9467.6768H630.861v6.9394h-3.3716V56.0786h7.1739a6.1447,6.1447,0,0,1,2.9467.6826,4.85,4.85,0,0,1,1.9751,1.9258,6.2486,6.2486,0,0,1,0,5.752Zm15.8194,9.53L651.371,66.771h-2.0451v7.1982h-3.3716V56.0659h7.8379a4.9978,4.9978,0,0,1,2.5967.6641,4.5005,4.5005,0,0,1,1.7349,1.8769,6.7322,6.7322,0,0,1,0,5.6231,4.4814,4.4814,0,0,1-1.7412,1.8769,4.6377,4.6377,0,0,1-1.1708.4848l4.2777,7.3775Zm22.085-6.4111a7.1411,7.1411,0,0,1-.812,3.4639,5.6854,5.6854,0,0,1-2.2949,2.3134,7.5835,7.5835,0,0,1-6.8536,0,5.6854,5.6854,0,0,1-2.2949-2.3134,7.1411,7.1411,0,0,1-.812-3.4639V62.4888a7.1438,7.1438,0,0,1,.812-3.4639,5.6972,5.6972,0,0,1,2.2949-2.3135,7.5918,7.5918,0,0,1,6.8536,0,5.6972,5.6972,0,0,1,2.2949,2.3135,7.1438,7.1438,0,0,1,.812,3.4639Z"/>
//       <path d="M635.5705,59.604a2.0521,2.0521,0,0,0-1.0766-.2773h-3.8882v4.4541h3.8882a2.0283,2.0283,0,0,0,1.0644-.2764,1.9,1.9,0,0,0,.72-.7813,2.503,2.503,0,0,0,.2583-1.1572,2.6239,2.6239,0,0,0-.252-1.1748A1.8478,1.8478,0,0,0,635.5705,59.604Z"/>
//       <path d="M654.1893,59.5728a1.6479,1.6479,0,0,0-.9229-.2588h-4.1958v4.2089h4.1958a1.6259,1.6259,0,0,0,.9106-.2587,1.6741,1.6741,0,0,0,.6089-.7383,2.8225,2.8225,0,0,0,.2276-1.1074,2.683,2.683,0,0,0-.2154-1.1075A1.6737,1.6737,0,0,0,654.1893,59.5728Z"/>
//     </g>
//     <g style="fill:#3c4b64">
//       <g>
//         <path d="M99.835,36.0577l-39-22.5167a12,12,0,0,0-12,0l-39,22.5166a12.0339,12.0339,0,0,0-6,10.3924V91.4833a12.0333,12.0333,0,0,0,6,10.3923l39,22.5167a12,12,0,0,0,12,0l39-22.5167a12.0331,12.0331,0,0,0,6-10.3923V46.45A12.0334,12.0334,0,0,0,99.835,36.0577Zm-2,55.4256a4,4,0,0,1-2,3.4641l-39,22.5167a4.0006,4.0006,0,0,1-4,0l-39-22.5167a4,4,0,0,1-2-3.4641V46.45a4,4,0,0,1,2-3.4642l39-22.5166a4,4,0,0,1,4,0l39,22.5166a4,4,0,0,1,2,3.4642Z"/>
//         <path d="M77.8567,82.0046h-2.866a4,4,0,0,0-1.9247.4934L55.7852,91.9833,35.835,80.4648V57.4872l19.95-11.5185,17.2893,9.4549a3.9993,3.9993,0,0,0,1.9192.4906h2.8632a2,2,0,0,0,2-2V51.2024a2,2,0,0,0-1.04-1.7547L59.628,38.9521a8.0391,8.0391,0,0,0-7.8428.09L31.8346,50.56a8.0246,8.0246,0,0,0-4,6.9287v22.976a8,8,0,0,0,4,6.9283l19.95,11.5186a8.0429,8.0429,0,0,0,7.8433.0879l19.19-10.5312a2,2,0,0,0,1.0378-1.7533v-2.71A2,2,0,0,0,77.8567,82.0046Z"/>
//       </g>
//       <g>
//         <path d="M172.58,45.3618a15.0166,15.0166,0,0,0-15,14.9995V77.6387a15,15,0,0,0,30,0V60.3613A15.0166,15.0166,0,0,0,172.58,45.3618Zm7,32.2769a7,7,0,0,1-14,0V60.3613a7,7,0,0,1,14,0Z"/>
//         <path d="M135.9138,53.4211a7.01,7.01,0,0,1,7.8681,6.0752.9894.9894,0,0,0,.9843.865h6.03a1.0108,1.0108,0,0,0,.9987-1.0971,15.0182,15.0182,0,0,0-15.7162-13.8837,15.2881,15.2881,0,0,0-14.2441,15.4163V77.2037A15.288,15.288,0,0,0,136.0792,92.62a15.0183,15.0183,0,0,0,15.7162-13.8842,1.0107,1.0107,0,0,0-.9987-1.0971h-6.03a.9894.9894,0,0,0-.9843.865,7.01,7.01,0,0,1-7.8679,6.0757,7.1642,7.1642,0,0,1-6.0789-7.1849V60.6057A7.1638,7.1638,0,0,1,135.9138,53.4211Z"/>
//         <path d="M218.7572,72.9277a12.1585,12.1585,0,0,0,7.1843-11.0771V58.1494A12.1494,12.1494,0,0,0,213.7921,46H196.835a1,1,0,0,0-1,1V91a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V74h6.6216l7.9154,17.4138a1,1,0,0,0,.91.5862h6.5911a1,1,0,0,0,.91-1.4138Zm-.8157-11.0771A4.1538,4.1538,0,0,1,213.7926,66h-9.8511V54h9.8511a4.1538,4.1538,0,0,1,4.1489,4.1494Z"/>
//         <path d="M260.835,46h-26a1,1,0,0,0-1,1V91a1,1,0,0,0,1,1h26a1,1,0,0,0,1-1V85a1,1,0,0,0-1-1h-19V72h13a1,1,0,0,0,1-1V65a1,1,0,0,0-1-1h-13V54h19a1,1,0,0,0,1-1V47A1,1,0,0,0,260.835,46Z"/>
//         <path d="M298.835,46h-6a1,1,0,0,0-1,1V69.6475a7.0066,7.0066,0,1,1-14,0V47a1,1,0,0,0-1-1h-6a1,1,0,0,0-1,1V69.6475a15.0031,15.0031,0,1,0,30,0V47A1,1,0,0,0,298.835,46Z"/>
//         <rect x="307.835" y="46" width="8" height="38" rx="1"/>
//       </g>
//     </g>
//   </g>
// `]

export const logo = ['690 334', `
  <title>Poin Nine Portal</title>
  <?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="svg970" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 2868.3 1785.7" style="enable-background:new 0 0 2868.3 1785.7;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#470A68;}
	.st1{fill:#FFFFFF;}
</style>
<g id="g3174">
	<path id="path1430_1_" class="st0" d="M2868.3,1649.2"/>
	<g id="g1431">
		<path id="path1432_1_" class="st1" d="M1416.4,301.2l-86-149l173.1-100l86.1,149L1416.4,301.2z"/>
		<path id="path1433_1_" class="st1" d="M1632.6,878.3V333.5h180.9V414c55.8-61.7,117.1-92.6,184-92.6c65.7,0,117.2,20.3,155.2,60.7
			c37.6,39.8,56.6,94.4,56.6,164v332.1h-198.9V605.8l-0.9-43.7c0-20.6-7.7-37.8-22.9-51.8c-14.2-14.4-33.6-22.3-53.8-21.8
			c-35.6,0-63.5,15.3-83.5,45.7c-12.1,17.8-17.8,45.1-17.8,81.5v262.4h-198.9V878.3z"/>
		<path id="path1434_1_" class="st1" d="M710.7,1472.3V927.5l199-1v80.6c55.6-61.6,98.9-91.4,165.9-91.4
			c65.6,0,117.3,20.2,155.1,60.6c37.7,39.8,56.6,94.5,56.6,164v332.1h-198.9v-272.4l-1-43.7c0.3-19.8-8.1-38.6-22.9-51.7
			c-14.6-14.7-32.4-21.8-53.6-21.8c-35.8,0-63.8,15.3-83.7,45.7c-11.8,18-17.8,45.2-17.8,81.5v262.5L710.7,1472.3z"/>
		<path id="path1435_1_" class="st1" d="M1632.3,1472.3V927.5h180.9v80.6c55.7-61.7,117-92.5,183.9-92.5
			c65.7,0,117.5,20.2,155.2,60.6c37.6,39.8,56.5,94.5,56.6,164v332.1H2010v-272.4l-0.9-43.7c0.3-19.8-8-38.7-22.9-51.7
			c-14.6-14.7-32.4-21.8-53.6-21.8c-35.7,0-63.8,15.3-83.7,45.7c-11.9,18-17.9,45.2-17.8,81.5v262.5L1632.3,1472.3z"/>
		<path id="path1436_1_" class="st1" d="M1416.4,1750.1l-86.1-149l173-99.8l86,149L1416.4,1750.1z"/>
		<path id="path1437_1_" class="st1" d="M2271.8,333.5V202.2h198.9v131.3H2595v150.1h-123.4v207.9c-0.1,12.3,4.2,24.3,12,33.9
			c12.6,14.6,33.7,21.8,63.5,21.8c16.6,0,35.3-3.4,55.8-10l20.9,126.2c-53,17.2-100.2,25.9-141.2,25.9
			c-64.5,0-115.6-15.2-154.2-45.7c-20.1-16.6-35.5-38.3-44.6-62.7c-8.1-23.8-12-59-12-105.3V333.5z"/>
		<path id="path1438_1_" class="st1" d="M1360.2,1472.4V334h199.8v1138.4H1360.2z"/>
		<path id="path1439_1_" class="st1" d="M407,317.4c-67.2,0-120.5,20.6-161.7,61.7c-7.2,8-12,14.3-14.7,17.5
			c-1.9,2.7-5.7,8.3-11.6,17.7v-82.2H33.9V1078h202.7V828.4c51.8,41.7,104.9,61.6,158.6,61.6c72.3,0,130.5-27.2,176.3-82.2
			c46.3-55.2,70.4-123,70.4-208.5c0-86.2-22.7-155.8-64.6-205.6C535.5,343.3,478.4,317.4,407,317.4z M339.3,473.1
			c31.1,0,54.7,10.7,73.4,35.3c19.3,23.9,29.4,54.8,29.4,94c0,39.1-7.9,72.4-26.4,97c-17.9,23.8-43.7,35.3-73.5,35.3
			c-31.1,0-55.7-12.7-76.2-35.3c-20.7-23.3-29.5-52.3-29.5-88.1v-8.9c0-38.3,7.3-70.1,26.5-94C282.8,483.8,308.7,473.1,339.3,473.1
			L339.3,473.1z"/>
		<path id="path1443_1_" class="st1" d="M991.2,323.2c-94.3,0-172,24-229.2,76.3c-56.4,51.7-82.2,122.3-82.2,208.5
			c0,84.1,28.3,150.9,85.2,202.5c57.6,51.7,133.3,79.3,226.2,79.3c94.6,0,168.9-27.5,226.1-79.3c57-52.3,85-120.1,85-205.6
			c0-84.9-27.3-153.8-85-205.6C1159.7,347.9,1084.7,323.2,991.2,323.2z M991.2,464.2c31.1,0,54,12.4,73.4,38.2
			c19.3,25.1,29.4,60.3,29.4,102.7c0,41.7-9.5,76.3-29.4,102.7c-19.2,25.7-42.9,38.1-73.4,38.1c-30.5,0-57.1-12.4-76.3-38.1
			s-29.4-60.4-29.4-102.7c0-41.1,10.7-76.2,29.4-102.7C934.7,476.5,960.8,464.2,991.2,464.2z"/>
		<path id="path1444" class="st1" d="M2551.4,914.7c-84.7,0-156.1,24-208.4,76.3s-76.4,122.1-76.4,205.6
			c0,86.8,29.7,156.8,88.1,208.4c58.9,51.7,134.2,76.4,229,76.4c61.7,0,117.9-10.2,170.3-29.4l43.9-17.6l-49.9-120.4
			c-59.6,21.8-111,32.3-152.8,32.4c-35.7,0-65.9-5.9-91-20.6c-24.6-15.3-38.5-34.9-41.2-58.8h364.1l3-29.4
			c0-103.4-25.1-184.5-73.4-240.7C2708.5,940.6,2641,914.7,2551.4,914.7z M2551.4,1046.7c53.9,0,88,30.8,100,91.1h-193.9
			c5.2-28.6,16.5-48.8,32.3-64.5C2506.6,1057.2,2526.4,1046.7,2551.4,1046.7L2551.4,1046.7z"/>
	</g>
</g>
</svg>

`]

export const logoLoginPage = ['690 334', `
  <title>Poin Nine Portal</title>
  <?xml version="1.0" encoding="UTF-8"?>
  <svg xmlns="http://www.w3.org/2000/svg" id="svg970" viewBox="0 0 2868.34 2755.57">
	 <defs>
		<style>.cls-1{fill:#470a68;}.cls-2{fill:#fff;}</style>
	 </defs>
	 <g id="g3174">
		<path id="path1430" class="cls-1" d="M1003.86,2788.08,51.72,1138.8,1967.83,32.51l952.23,1649.24Z" transform="translate(-51.72 -32.51)" />
		<g id="g1431">
		   <path id="path1432" class="cls-2" d="M1918,1136.63l-47.27-81.89,95.16-55,47.32,81.89Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1433" class="cls-2" d="M2036.84,1453.86V1154.37h99.46v44.24q46-50.88,101.15-50.88c36.13,0,64.45,11.15,85.31,33.36,20.69,21.9,31.1,51.87,31.1,90.17v182.56H2244.51V1304.05l-.47-24c0-11.32-4.25-20.77-12.58-28.5a40.35,40.35,0,0,0-29.58-12c-19.56,0-34.92,8.41-45.89,25.15-6.63,9.76-9.8,24.77-9.8,44.81v144.26H2036.84Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1434" class="cls-2" d="M1530.06,1780.42V1480.88l109.39-.56v44.33c30.58-33.88,54.39-50.27,91.22-50.27q54.13,0,85.27,33.31,31.1,32.85,31.14,90.17v182.56H1737.74V1630.65l-.56-24a37.05,37.05,0,0,0-12.59-28.41c-8-8.06-17.82-12-29.49-12-19.69,0-35.09,8.41-46,25.11q-9.76,14.84-9.76,44.81v144.3Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1435" class="cls-2" d="M2036.71,1780.42V1480.88h99.42v44.33q45.91-50.88,101.1-50.83,54.2,0,85.31,33.31,31,32.85,31.1,90.17v182.56H2244.3V1630.65l-.48-24a36.91,36.91,0,0,0-12.58-28.41c-8-8.06-17.83-12-29.49-12-19.65,0-35.09,8.41-46,25.11q-9.83,14.84-9.8,44.81v144.3Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1436" class="cls-2" d="M1918,1933.1l-47.32-81.89,95.12-54.87,47.28,81.89Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1437" class="cls-2" d="M2388.25,1154.37v-72.18H2497.6v72.18h68.32v82.49h-67.84v114.29a29.07,29.07,0,0,0,6.59,18.61c6.94,8,18.52,12,34.92,12,9.11,0,19.39-1.87,30.66-5.51l11.5,69.39c-29.15,9.46-55.09,14.23-77.64,14.23-35.44,0-63.55-8.37-84.76-25.11a81.73,81.73,0,0,1-24.5-34.48c-4.47-13.1-6.6-32.45-6.6-57.91v-188Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1438" class="cls-2" d="M1887.08,1780.46V1154.63h109.81v625.83Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1439" class="cls-2" d="M1363.08,1145.52c-36.92,0-66.23,11.32-88.88,33.92-3.94,4.38-6.59,7.85-8.06,9.63-1.05,1.47-3.13,4.59-6.38,9.71v-45.19H1158v410.05h111.42V1426.45c28.46,22.9,57.65,33.88,87.18,33.88,39.73,0,71.74-14.93,96.9-45.2,25.46-30.32,38.69-67.62,38.69-114.64,0-47.36-12.49-85.66-35.52-113C1433.77,1159.75,1402.37,1145.52,1363.08,1145.52Zm-37.18,85.58c17.09,0,30.06,5.89,40.34,19.38,10.59,13.14,16.14,30.15,16.14,51.66s-4.34,39.82-14.53,53.31c-9.85,13.09-24,19.39-40.43,19.39-17.09,0-30.62-7-41.9-19.39-11.36-12.8-16.22-28.76-16.22-48.41v-4.9c0-21.08,4-38.52,14.57-51.66C1294.85,1237,1309.08,1231.1,1325.9,1231.1Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1443" class="cls-2" d="M1684.26,1148.73c-51.83,0-94.56,13.18-126,41.94-31,28.41-45.2,67.23-45.2,114.64,0,46.23,15.57,82.93,46.81,111.34,31.66,28.41,73.3,43.59,124.35,43.59,52,0,92.86-15.14,124.31-43.59,31.35-28.76,46.75-66,46.75-113,0-46.68-15-84.54-46.75-113C1776.86,1162.26,1735.66,1148.73,1684.26,1148.73Zm0,77.51c17.09,0,29.71,6.81,40.33,21,10.59,13.79,16.14,33.14,16.14,56.47,0,22.9-5.21,41.94-16.14,56.47-10.53,14.14-23.59,20.95-40.33,20.95s-31.41-6.81-41.94-20.95-16.14-33.22-16.14-56.47c0-22.6,5.9-41.9,16.14-56.47C1653.2,1233,1667.52,1226.24,1684.26,1226.24Z" transform="translate(-51.72 -32.51)" />
		   <path id="path1444" class="cls-2" d="M2542.92,1474.86c-46.58,0-85.79,13.18-114.58,41.94s-42,67.1-42,113c0,47.71,16.35,86.18,48.45,114.59,32.4,28.41,73.78,42,125.87,42,33.92,0,64.8-5.6,93.64-16.14l24.16-9.67-27.41-66.19c-32.79,12-61,17.74-84,17.79-19.6,0-36.22-3.26-50-11.33-13.53-8.41-21.16-19.17-22.64-32.31h200.13l1.65-16.18c0-56.82-13.8-101.4-40.34-132.33S2592.2,1474.86,2542.92,1474.86Zm0,72.56c29.63,0,48.37,16.92,55,50.09H2491.31c2.86-15.7,9.06-26.8,17.78-35.47C2518.29,1553.19,2529.18,1547.42,2542.92,1547.42Z" transform="translate(-51.72 -32.51)" />
		</g>
	 </g>
  </svg>
  

`]