import React, {Component} from 'react';
import {BrowserRouter, HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import {LoginRequiredRoute} from './components/auth';
import {Auth} from './components/base/auth';
import 'react-table-6/react-table.css';
import 'react-toastify/dist/ReactToastify.css';
import './scss/style.scss';
import './views/views.scss';
import { SrvComm } from './components/base/srvcomm';
import { getNested } from './p9libs/utils/misc';
import { redirect } from './uimisc';
import {setPortalService} from "./components/portal/store_aux";
import store, {ACTION_TYPE_REFRESH_ISSUES, ACTION_TYPE_UPDATE_PORTAL_FEATURES} from "./store";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const PasswordRecovery = React.lazy(() => import('./views/pages/login/password_recovery'));


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      'portal_service': '',
      'portal_services': null,
    };
  }

  componentDidMount() {
    this._loadConfiguration();
  }

  render() {
    return (
      <>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login onLogin={this._onLogin}
                                                                                   {...props} />}/>
              <Route path="/password_recovery/:recovery_key" name="Password Recovery" render={props => <PasswordRecovery
                {...props} />}/>
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />}/>
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />}/>
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />}/>
              <Route exact path="/logout" render={props => {
                Auth.makeLogoutRequest(null, null, () => {
                  Auth.logout();
                  redirect('/');
                });
                return null;
                // return (<Redirect to="/" />);
              }}/>
              {/* <Route path="/" name="Home" render={props => <TheLayout {...props}/>} /> */}
              <LoginRequiredRoute path="/" name="Home"
                                  render={props => this.renderMainContent(props)}/>
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </>
    );
  }

  renderMainContent(props) {
    if (this.state['portal_services'] === null) {
      return this.renderLoading();
    }
    return this.renderLoaded(props);
  }

  renderLoading() {
    return <Route><span>Loading...</span></Route>;
  }

  renderLoaded(props) {
    return (
      <TheLayout
        portalService={this.state['portal_service']}
        portalServices={this.state['portal_services']}
        onChangePortalService={this._onChangePortalService}
        {...props} />
    );
    // return (
    //   <LoginRequiredRoute path="/" name="Home"
    //     render={props => <TheLayout
    //       portalService={this.state['portal_service']}
    //       portalServices={this.state['portal_services']}
    //       onChangePortalService={this._onChangePortalService}
    //       {...props} />
    //     } />
    // );
  }

  _onLogin = () => {
    this._loadConfiguration();
  }

  // _onLogout = () => {
  //   this._clearConfiguration();
  // }

  _onChangePortalService = (newService) => {
    const urlPortalService = this._getPortalServiceFromUrl(this.state['portal_services']);
    if ((urlPortalService !== '') && (urlPortalService !== newService)) {
      if (newService === 'deriv') newService = 'derivatives';

      // redirect('/');
      // history.push('/')
      window.location.href = `/${newService}`;
      return;
    }

    this.setState({
      'portal_service': newService,
    });
  }

  _clearConfiguration() {
    this.setState({
      'portal_service': '',
      'portal_services': null,
    });
  }

  _loadConfiguration() {
    SrvComm.sendCommand('portal/get_configuration', {}, (respData, requestId) => {
      const portalServices = getNested(respData, ['portal_services'], []);

      const stateUpdate = {
        'portal_services': portalServices,
      };
      const currentPortalService = this.state['portal_service'];
      if (currentPortalService == '') {
        const probablePortalService = this._getPortalServiceFromUrl(portalServices);
        stateUpdate['portal_service'] = probablePortalService;
        setPortalService(probablePortalService);
      }

      const portalFeatures = getNested(respData, ['features'], {});
      store.dispatch({'type': ACTION_TYPE_UPDATE_PORTAL_FEATURES, portalFeatures: portalFeatures});

      this.setState(stateUpdate);
    }, respData => {
      console.log('Error');
      console.log(respData);
    }, true);
  }

  _getPortalServiceFromUrl(portalServicesInfo) {
    const portalServices = portalServicesInfo.map(item => item.code);
    const urlParts = window.location.pathname.split('/').filter(item => item != '');
    if (urlParts.length == 0) return '';

    let probablePortalService = urlParts[0];
    if (probablePortalService === 'derivatives') {
      probablePortalService = 'deriv';
    }

    if (portalServices.includes(probablePortalService)) {
      return probablePortalService;
    }

    return '';
  }
}

export default App;
