import { createHashHistory, createBrowserHistory } from 'history';


export function redirect(url) {
  // const history = createBrowserHistory();
  // history.push(url);
  // history.replace(url);
  // // window.location.redirect(url);

  if (url === window.location.pathname) return;
  window.location.href = url;
}
